const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://turbo.dvgeo.app' : 'http://192.168.100.106:3053',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://turbo.dvgeo.app' : 'http://192.168.100.106:3053',
    api: 'api/',
    apiSocket: 'turbo::1.2',
    nameDir: 'turbo',
    package: 'app.dvgeo.turbo.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyASO5lbzLyO5XZRwsP7IMmH6fgS_4elgME',
    appName: 'Turbo',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#000000',
    colorDark: '#EF6B30',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.dvgeo.turbo.passenger',
    playStoreDriverId: 'app.dvgeo.turbo.driver',
    appStorePassengerId: '6523490851',
    appStoreDriverId: '6523521793',
    email: "sasdigitalsystems@gmail.com",
};
export default config;
